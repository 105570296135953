import React, {useMemo} from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createURLParams } from "../videoUtils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function History({ videoInfo }) {
  const { t } = useTranslation();
  const query = useQuery();
  const lang = useMemo(() => query.get("lang"), [query]);
  const pathArray = window.location.pathname.split("/");
  const lastSegment = pathArray[pathArray.length - 1];
  let history = [];
  let list = [];
  if (Object.keys(videoInfo).includes(lastSegment)) {
    const historyStg = localStorage.getItem("history") || "";
    if (historyStg.length > 0) {
      history = JSON.parse(historyStg);
    }
    history = history.filter((data) => {
      return data !== lastSegment;
    });
    list = history.map((name) => {
      return Object.entries(videoInfo).filter(([key, data]) => {
        return key === name;
      });
    });
    history.unshift(lastSegment);
    localStorage.setItem("history", JSON.stringify(history.slice(0, 4)));
  }
  return (
    <div className="p-video-below__history col-12 col-md-3">
      <h3 className="u-headline4 u-mb12p">{t("History.title")}</h3>
      <ul className="c-vertical-list row row-cols-4 row-cols-md-1">
        {list.slice(0, 3).map((data) => {
          if (data.length !== 0) {
            return (
              <li key={data[0][0]}>
                <a href={`${window.location.origin}/watch/${encodeURI(data[0][0])}${createURLParams({lang: lang})}`}>
                  <p className="c-vertical-list--img">
                    <img
                      alt={data[0][1].title}
                      src={`${window.location.origin + data[0][1].thumbnail}`}
                      className="u-fit"
                    />
                  </p>
                  <p className="c-vertical-list--text">{data[0][1].title}</p>
                </a>
              </li>
            );
          } else {
            return <div />;
          }
        })}
      </ul>
    </div>
  );
}
