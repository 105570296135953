import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCustomizeData = createAsyncThunk(
  "customizeData/fetchCustomizeData",
  async () => {
    const response = await fetch(
      `${window.location.origin}/video-material/required/customize.json`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    return await response.json();
  }
);

const customizeSlice = createSlice({
  name: "customizeData",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomizeData.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default customizeSlice.reducer;
export const getCustomizeByKey = (key) => (state) => state.customizeData[key];
