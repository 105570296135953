import React, {useMemo} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { createURLParams } from "../videoUtils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function RecommendedVideo({ videoInfo }) {
  const { t } = useTranslation();
  const query = useQuery();
  const lang = useMemo(() => query.get("lang"), [query]);
  const list = Object.entries(videoInfo).filter((data) => {
    return data[1].tags === "recommendation";
  });

  return (
    <>
      <h5 className="u-headline4 u-mb16p">
            <img
              alt={"Document"}
              src={`${window.location.origin}/video-material/img/wakaba-icon.png`}
              style={{
                paddingRight: "10px",
                verticalAlign: "middle",
                display: "inline-flex",
                width: "32px",
              }}
            />
            {t("Home.recommendedVideo")}</h5>
      <ul className="c-thumbnail-list row row-cols-2 row-cols-sm-3 row-cols-md-3">
        {list.map(([key, data]) => (
          <li key={`recommendation-${key}`}>
            <a href={`${window.location.origin}/watch/${key}${createURLParams({lang: lang})}`}>
              <img
                src={`${window.location.origin + data.thumbnail}`}
                alt={key}
                loading="lazy"
                className="u-fit"
              />
            </a>
          </li>
        ))}
      </ul>
    </>
  );
}
