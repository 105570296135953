import React from "react";
import RecommendedVideo from "./RecommendedVideo";
import AllVideo from "./AllVideo";

export default function PlayList({ videoInfo }) {
  return (
    <>
      <div className="l-wrapper--small">
        <RecommendedVideo videoInfo={videoInfo} />
      </div>
      <div className="l-wrapper--small">
        <AllVideo videoInfo={videoInfo} />
      </div>
    </>
  );
}
