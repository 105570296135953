import React, {useMemo} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { createURLParams } from "../videoUtils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AllVideo({ videoInfo }) {
  const { t } = useTranslation();
  const query = useQuery();
  const lang = useMemo(() => query.get("lang"), [query]);
  const list = Object.entries(videoInfo).filter((data) => {
    return data[1].tags === "command";
  });

  return (
    <>
      <h5 className="u-headline4 u-mb16p">
        <img
          alt={"Document"}
          src={`${window.location.origin}/video-material/img/search-icon.png`}
          style={{
            paddingRight: "10px",
            verticalAlign: "middle",
            display: "inline-flex",
            width: "32px",
          }}
        />
        {t("Home.allVideo")}
      </h5>
      {list.length !== 0 ? (
        <ul className="c-thumbnail-command-list row row-cols-2 row-cols-sm-4 row-cols-md-5">
          {list.map(([key, data]) => (
            <li key={key}>
              <div>
              <a href={`${window.location.origin}/watch/${key}${createURLParams({lang: lang})}`}>
                  <img
                    src={`${window.location.origin + data.thumbnail}`}
                    alt={key}
                    loading="lazy"
                    className="u-fit"
                  />
                </a>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <ul className="c-thumbnail-command-list row row-cols-2 row-cols-sm-4 row-cols-md-5">
          <li key="coming-soon">
            <img
              src={`${window.location.origin}/video-material/img/coming-soon.png`}
              alt={"coming-soon"}
              loading="lazy"
              className="u-fit"
            />
          </li>
        </ul>
      )}
    </>
  );
}
