import * as React from "react";
import { TableOfContents } from "./TableOfContents";
import { RelatedVideos } from "./RelatedVideos";
import { useEffect } from "react";
import { handleResize } from "../Player";

export function SidePanel({ videoInfo }) {
  useEffect(() => {
    handleResize();
  }, [videoInfo]);
  return (
    <div className="p-video-player__detail col-12 col-md-3 side-panel">
      {videoInfo.length === 0 ? (
        <div></div>
      ) : (
        <RelatedVideos videoInfo={videoInfo} />
      )}
      <div className="p-video-player__index">
        <h3 className="p-video-player__index--title u-headline5">目次</h3>
        {videoInfo.length === 0 ? (
          <div></div>
        ) : (
          <TableOfContents videoInfo={videoInfo} />
        )}
      </div>
    </div>
  );
}
