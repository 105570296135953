import videojs from "video.js";
import "video.js/dist/video-js.css";
import React, { useEffect, useMemo, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  convertTimestampToSec,
  createURLParams,
  getUrlParam,
} from "../videoUtils";
import { useTranslation } from "react-i18next";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let lastTime = -1;
let lastId = -1;

function useValueRef(val) {
  const ref = React.useRef(val);
  React.useEffect(() => {
    ref.current = val;
  }, [val]);
  return ref;
}

export function VideoPlayer({ videoInfo }) {
  const { t } = useTranslation();
  const params = useParams();
  const query = useQuery();
  const lang = useMemo(() => query.get("lang"), [query]);

  const playerRef = useRef();
  const videoId = params["videoId"];
  const startSec = getUrlParam("sec") === null ? 0 : getUrlParam("sec");
  const videoData = videoInfo[videoId];
  const navigation = useNavigate();

  useEffect(() => {
    document.title = t("App.browserTitle", { title: videoData?.title });
    if (!videoData) {
      // TODO: YouTubeのように動画が再生できない旨を伝えるページに飛ばしたい
      navigation("/", { lang: lang });
    }
  });
  const videoJsOptions = {
    preload: "auto",
    controlsList: "nodownload",
    autoplay: false,
    controls: true,
    aspectRatio: "16:9",
    noUITitleAttributes: false,
    title: `${videoData?.title}`,
    playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    autoPictureInPicture: true,
    sources: [
      {
        src: `../video/${videoId}/${videoId}.m3u8`,
        type: "application/x-mpegURL",
      },
    ],
    poster: `${videoData?.thumbnail}`,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [remainSec, setRemainSec] = React.useState(5);
  const refRemainSec = useValueRef(remainSec);

  const [intervalId, setIntervalId] = React.useState(0);
  const refIntervalId = useValueRef(intervalId);

  const [openAutoPlayDialog, setOpenAutoPlayDialog] = React.useState(false);
  const handleOpenAutoPlayDialog = () => setOpenAutoPlayDialog(true);
  const handleCloseAutoPlayDialog = () => {
    clearInterval(refIntervalId.current);
    setOpenAutoPlayDialog(false);
  };
  const timeStampList = videoData?.timestamps.map((timestamp) =>
    convertTimestampToSec(timestamp.time)
  );

  useEffect(() => {
    timeStampList?.forEach((it) => {
      if (lastTime <= startSec && startSec >= it && lastId !== it) {
        const id = `timestamp-list-${it}`;
        const el = document.getElementById(id);
        el.classList.add("p-video-player__index--selected");
      }
      lastId = it;
      lastTime = startSec;
    });
    const player = videojs(playerRef.current, videoJsOptions, () => {
      const videoElem = document.getElementById("vjs_video_3_html5_api");
      videoElem.addEventListener("timeupdate", function () {
        timeStampList.forEach((it) => {
          const currentValue = parseInt(videoElem.currentTime);
          if (lastTime <= currentValue && currentValue >= it && lastId !== it) {
            Array.from(
              document.getElementsByClassName("p-video-player__index--selected")
            ).forEach((el) => {
              el.classList.remove("p-video-player__index--selected");
            });
            document
              .getElementById(`timestamp-list-${it}`)
              .classList.add("p-video-player__index--selected");
            // el.scrollIntoView(true)
            lastId = it;
          }
          lastTime = currentValue;
        });
      });
      //TODOもう少し処理をスッキリさせる
      videoElem.addEventListener("ended", function () {
        if (videoId === "introduction") {
          handleOpen();
        }
        if (videoData?.nextVideo !== "") {
          handleOpenAutoPlayDialog();
          setIntervalId(
            setInterval(() => {
              if (refRemainSec.current === 0) {
                window.location.href = `${window.location.origin}/watch/${
                  videoData?.nextVideo
                }${createURLParams({ sec: 0, lang: lang })}`;
              }
              if (refRemainSec.current >= 1) {
                setRemainSec((pre_count) => pre_count - 1);
              }
            }, 1000)
          );
        }
      });
    });

    return () => {
      // player.dispose();
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 680,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
  };
  const suggestionVideos = videoData?.suggestionVideos || [];

  return (
    <div className="p-video-player__video col-12 col-md-9">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3 id="modal-modal-title" class="u-mb32p">{t("VideoPlayer.endMessage")}</h3>
          <p id="modal-modal-description" class="u-mb20p u-ta--center">
            {t("VideoPlayer.endDescription1")}
            <br />
            {t("VideoPlayer.endDescription2")}
          </p>
          <ul className="row row-cols-2 row-cols-md-2">
            {suggestionVideos.map((info) => (
              <li key={info} style={{ cursor: "pointer" }}>
                <div
                  role="button"
                  onClick={() =>
                    (window.location.href = `${
                      window.location.origin
                    }/watch/${info}${createURLParams({ sec: 0, lang: lang })}`)
                  }
                >
                  <img
                    alt="complex"
                    src={`${
                      window.location.origin +
                      "/video-material/img/" +
                      info +
                      ".png"
                    }`}
                    style={{
                      height: "150px",
                      borderRadius: "12px",
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </Box>
      </Modal>
      <Modal
        open={openAutoPlayDialog}
        onClose={handleCloseAutoPlayDialog}
        aria-labelledby="modal-modal-title-1"
        aria-describedby="modal-modal-description-1"
      >
        <Box sx={style}>
          <div
            role="button"
            onClick={() =>
              (window.location.href = `${window.location.origin}/watch/${
                videoData?.nextVideo
              }${createURLParams({ sec: 0, lang: lang })}`)
            }
          >
            <h4
              id="modal-modal-title"
              className="u-headline4 u-ta--center u-mb20p"
            >
              {t("VideoPlayer.endNextVideoMessage", { sec: remainSec })}
            </h4>

            <img
              style={{
                cursor: "pointer",
                height: "150px",
                borderRadius: "12px",
              }}
              alt="complex"
              src={`${window.location.origin}/video-material/img/${videoData?.nextVideo}.png`}
            />
          </div>
        </Box>
      </Modal>
      <div className="p-video-player__video--inner">
        <div data-vjs-player>
          <video ref={playerRef} className="video-js vjs-big-play-centered" />
        </div>
      </div>
    </div>
  );
}
