import * as React from "react";
import { useParams } from "react-router-dom";
import { convertTimestampToSec } from "../videoUtils";

function _onClick(sec) {
  let videoElem = document.getElementById("vjs_video_3_html5_api");
  videoElem.currentTime = sec;
}

export function TableOfContents({ videoInfo }) {
  const data = videoInfo[useParams()["videoId"]];
  return (
    <ul className="p-video-player__index--list video-side">
      {data?.timestamps.map((info) => (
        <li
          key={`key-${info.time}`}
          id={`timestamp-list-${convertTimestampToSec(info.time)}`}
        >
          <div
            className="p-video-player__index__conts"
            tabIndex="0"
            role="button"
            onClick={(e) => _onClick(convertTimestampToSec(info.time))}
          >
            <span className="p-video-player__index__conts--title">
              {info.title}
            </span>
            <span className="p-video-player__index__conts--time">
              {info.time}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
}
