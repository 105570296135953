import React, { useEffect, useState } from "react";
import RecommendedVideo from "./component/RecommendedVideo";
import AllVideo from "./component/AllVideo";
import { useTranslation } from "react-i18next";

const Home = ({ videoInfo }) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("Home.title");
  });
  return (
    <main id="top" className="p-home">
      <section className="p-home-head">
        <div className="l-wrapper">
          <RecommendedVideo videoInfo={videoInfo} />
        </div>
      </section>
      <section className="p-home-conts">
        <div className="l-wrapper">
          <AllVideo videoInfo={videoInfo} />
        </div>
      </section>
    </main>
  );
};

export default Home;
