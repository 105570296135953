import i18n from "i18next";
import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    currentLanguage: "ja",
  },
  reducers: {
    setLanguage: (state, action) => {
      const language = action.payload;
      if (language !== "ja" && language !== "en") return;
      state.currentLanguage = language;
      i18n.changeLanguage(language);
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
