import React, {useMemo} from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createURLParams } from "../videoUtils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function RelatedVideos({ videoInfo }) {
  const data = videoInfo[useParams()["videoId"]];
  const { t } = useTranslation();
  const query = useQuery();
  const lang = useMemo(() => query.get("lang"), [query]);
  return (
    <div>
      {data?.steps.length > 0 ? (
        <div className="p-video-player__index u-mb12p">
          <h3 className="p-video-player__index--title u-headline5">{t("VideoAbout.relatedVideo")}</h3>
          <ul className="p-video-player__index--list video-side">
            {data?.steps.map((info, index) => (
              <li key={`key-${info.videoId}`}>
                <div
                  className="p-video-player__index__conts"
                  tabIndex="0"
                  role="button"
                  onClick={() => {
                    window.location.href = `${window.location.origin}/watch/${info.videoId}${createURLParams({lang: lang})}`;
                  }}
                  disabled={index === data?.currentStep}
                >
                  <div className="p-video-player__index__conts--inner">
                    <img
                      src={`${window.location.origin}/video-material/img/video-icon.png`}
                      style={{ marginRight: "10px" }}
                      alt={"video"}
                    />
                    <span className="p-video-player__index__conts--title">
                      {info.title}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
