import { configureStore } from "@reduxjs/toolkit";
import languageSlice from "./languageSlice";
import customizeSlice from "./customizeSlice";

export default configureStore({
  reducer: {
    language: languageSlice,
    customizeData: customizeSlice,
  },
});
