import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCustomizeByKey } from "../store/customizeSlice";
import { msgPriority } from "../videoUtils";

const FooterComp = () => {
  const { t } = useTranslation();
  const copyright = msgPriority(
    useSelector(getCustomizeByKey("App.copyright")),
    t("App.copyright")
  );

  return (
    <footer className="l-footer">
      <div className="l-footer__wrapper">
        <p className="l-footer__copyright">{copyright}</p>
      </div>
    </footer>
  );
};

export default FooterComp;
