import "video.js/dist/video-js.css";
import React, { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useParams } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { TableOfContents } from "./TableOfContents";
import { RelatedVideos } from "./RelatedVideos";
import { useTranslation } from "react-i18next";

export function VideoAbout({ videoInfo }) {
  const { t } = useTranslation();
  const videoData = videoInfo[useParams()["videoId"]];
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const [isWideScreen, setIsWideScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="p-video-below__detail col-12 col-md-9">
      <div className="c-tab-wrap">
        <label
          className={`c-tab-label ${activeTab === 0 ? "active" : ""} `}
          onClick={() => handleTabClick(0)}
        >
          {t("VideoAbout.description")}
        </label>
        <label
          className={`c-tab-label ${activeTab === 1 ? "active" : ""} `}
          onClick={() => handleTabClick(1)}
        >
          {t("VideoAbout.manual")}
        </label>
        {!isWideScreen && (
          <label
            className={`c-tab-label ${
              activeTab === 2 ? "active" : ""
            }  extra-content`}
            onClick={() => handleTabClick(2)}
          >
            {t("VideoAbout.tableOfContents")}
          </label>
        )}
        {!isWideScreen && (
          <label
            className={`c-tab-label ${
              activeTab === 3 ? "active" : ""
            } extra-content`}
            onClick={() => handleTabClick(3)}
          >
            {t("VideoAbout.relatedVideo")}
          </label>
        )}
        <div className="c-tab-inner">
          {activeTab === 0 && (
            <div className="c-tab-content">
              <div className="p-video-below__titleUnit">
                <h2 className="u-headline3">{videoData?.title}</h2>
              </div>
              <div className="p-video-below__detailUnit">
                <div className="p-video-below__detailUnit--txt">
                  <p>{videoData?.description}</p>
                </div>
                <div className="p-video-below__linkUnit ">
                  <div className="p-video-below__linkUnit__wrapper row">
                    <div className="col-12 col-md-12">
                      {videoData?.resources.length > 0 ? (
                        <div className="p-video-below__linkUnit__manual">
                          <h3 className="u-headline5 u-mb16p">
                            <img
                              alt={"Document"}
                              src={`${window.location.origin}/video-material/img/fileIcon.png`}
                              style={{
                                paddingRight: "12px",
                                verticalAlign: "middle",
                                display: "inline-flex",
                              }}
                            />
                            {t("VideoAbout.fileAndURL")}
                          </h3>
                          <ul className="p-video-below__detailUnit--list">
                            {videoData?.resources.map((resource, index) => (
                              <li key={`resource-${index}`}>
                                {resource.type === "file" ? (
                                  <FileDownloadIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                ) : (
                                  ""
                                )}
                                {resource.type === "link" ? (
                                  <OpenInNewIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                ) : (
                                  ""
                                )}
                                <Link
                                  href={encodeURI(resource.href)}
                                  target="_blank"
                                  underline="none"
                                >
                                  {resource.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 1 && (
            <div className="c-tab-content">
              <h4 className="u-headline5 u-mb16p">
                <img
                  alt={"Document"}
                  src={`${window.location.origin}/video-material/img/docIcon.png`}
                  style={{
                    paddingRight: "12px",
                    verticalAlign: "middle",
                    display: "inline-flex",
                  }}
                />
                {t("VideoAbout.readManual")}
              </h4>
              {videoData?.manual.length > 0 ? (
                <ul className="p-video-below__detailUnit--list">
                  {videoData?.manual.map((it, index) => (
                    <li key={`link-${index}`}>
                      <LaunchIcon fontSize="small" color="primary" />
                      <Link target="_blank" underline="none" href={it.link}>
                        {it.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <div></div>
              )}
            </div>
          )}
          {activeTab === 2 && (
            <div className="c-tab-content">
              <TableOfContents videoInfo={videoInfo} />
            </div>
          )}
          {activeTab === 3 && (
            <div className="c-tab-content">
              <RelatedVideos videoInfo={videoInfo} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
