import "./scss/style.scss";
import React, { useEffect, useMemo, useState } from "react";
import HeaderComp from "./component/HeaderComp";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Player from "./Player";
import Home from "./Home";
import FooterComp from "./component/FooterComp";
import { setLanguage } from "./store/languageSlice";
import { fetchCustomizeData, getCustomizeByKey } from "./store/customizeSlice";
import { Helmet } from "react-helmet";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const loadHead = async () => {
  try {
    const response = await fetch("/path/to/keys.json");
    const keys = await response.json();
    return keys.googleAnalyticsKey;
  } catch (error) {
    console.error("Failed to load Google Analytics key:", error);
    return null;
  }
};

const App = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const lang = useMemo(() => query.get("lang"), [query]);
  const [videoInfo, setVideoInfo] = useState([]);

  let isFetch = true;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/video-material/data.json`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonData = await response.json();
        setVideoInfo(jsonData);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }

      if (lang) {
        dispatch(setLanguage(lang));
      }
      dispatch(fetchCustomizeData());
    };
    if (isFetch) {
      fetchData();
      isFetch = false;
    }
  }, []);
  return (
    <div className="learnRobo">
      {/* The Helmet component allows you to manipulate the <head> tag */}
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${useSelector(
            getCustomizeByKey("Head.google.tag")
          )}`}
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${useSelector(
              getCustomizeByKey("Head.google.tag")
            )}');
          `}
        </script>
        <meta
          name="author"
          content={`${useSelector(getCustomizeByKey("Head.meta.author"))}`}
        />
        <meta
          name="description"
          content={`${useSelector(getCustomizeByKey("Head.meta.description"))}`}
        />
        <meta
          property="og:title"
          content={`${useSelector(getCustomizeByKey("Head.meta.og.title"))}`}
        />
        <meta
          property="og:description"
          content={`${useSelector(
            getCustomizeByKey("Head.meta.og.description")
          )}`}
        />
        <meta
          property="og:url"
          content={`${useSelector(getCustomizeByKey("Head.meta.og.url"))}`}
        />{" "}
        />
        <meta
          property="og:image"
          content={`${useSelector(getCustomizeByKey("Head.meta.og.image"))}`}
        />{" "}
        />
        <meta
          property="og:site_name"
          content={`${useSelector(
            getCustomizeByKey("Head.meta.og.site.name")
          )}`}
        />
      </Helmet>
      <HeaderComp />
      <Routes>
        <Route index path={`/`} element={<Home videoInfo={videoInfo} />} />
        <Route
          path={`/watch/:videoId`}
          element={<Player videoInfo={videoInfo} />}
        />
        <Route path="*" element={<Home videoInfo={videoInfo} />} />
      </Routes>
      <FooterComp />
    </div>
  );
};

export default App;
