import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function HeaderComp() {
  const { t } = useTranslation();
  const query = useQuery();
  const lang = useMemo(() => query.get("lang"), [query]);
  let homeUrl = window.location.origin;
  if (lang) homeUrl = `${homeUrl}?lang=${lang}`;

  return (
    <header className="l-header">
      <div className="l-header__wrapper">
        <h1>
          <a className="l-header--title" href={`${homeUrl}`}>
            <img
              alt={"Document"}
              src={`${window.location.origin}/video-material/required/logo160.png`}
              style={{
                paddingRight: "10px",
                verticalAlign: "middle",
                display: "inline-flex",
                width: "32px",
              }}
            />
            {t("App.title")}
          </a>
        </h1>
      </div>
    </header>
  );
}
