import React, { useEffect, useRef } from "react";
import { VideoPlayer } from "./component/VideoPlayer";
import { SidePanel } from "./component/SidePanel";
import { VideoAbout } from "./component/VideoAbout";
import PlayList from "./component/PlayList";
import { History } from "./component/History";

import { useLocation } from "react-router-dom";

const useLocationChange = (callback) => {
  const refCallback = useRef();
  const location = useLocation();

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  // ロケーションに変更があったときに処理実行
  useEffect(() => {
    if (refCallback.current) {
      refCallback.current(location);
    }
  }, [location]);
};

export function handleResize() {
  const lists = Array.from(document.getElementsByClassName("video-side"));
  lists.forEach((content) => {
    let resizedHeight = 150;
    if (document.body.clientWidth > 768) {
      const h = document.getElementById("vjs_video_3").offsetHeight;
      resizedHeight = h / lists.length - 60;
    }
    content.style.height = resizedHeight + "px";
  });
}

const Player = ({ videoInfo }) => {
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
  });

  useLocationChange(() => {
    handleResize();
  });
  return (
    <main id="top" className="p-video">
      <div>
        <section className="p-video-player">
          <div className="p-video-player__wrapper row">
            {/*TODO読み込み前のダミーもう少し最適化*/}
            {videoInfo.length === 0 ? (
              <div className="p-video-player__video col-12 col-md-9">
                <div className="p-video-player__video--inner"></div>
              </div>
            ) : (
              <VideoPlayer videoInfo={videoInfo} />
            )}
            <SidePanel videoInfo={videoInfo} />
          </div>
        </section>
        <section className="p-video-below">
          <div className="p-video-below__wrapper l-wrapper row">
            {videoInfo.length === 0 ? (
              <div></div>
            ) : (
              <VideoAbout videoInfo={videoInfo} />
            )}
            {videoInfo.length === 0 ? (
              <div></div>
            ) : (
              <History videoInfo={videoInfo} />
            )}
          </div>
        </section>
        <section className="p-video-footer">
          {videoInfo.length === 0 ? (
            <div></div>
          ) : (
            <PlayList videoInfo={videoInfo} />
          )}
        </section>
      </div>
    </main>
  );
};

export default Player;
