export function getUrlParam(name) {
  name = name.replace(/[[]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(window.location.search);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function convertTimestampToSec(time) {
  const min = parseFloat(time.split(":")[0]) * 60;
  const sec = parseFloat(time.split(":")[1]);
  return min + sec;
}

export function msgPriority(overwriteMsg, langMsg) {
  if(overwriteMsg) {
      return overwriteMsg;
  } else {
      return langMsg;
  }
}

export function createURLParams(params) {
  const validParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value != null && value !== '')
  );
  if(Object.keys(validParams).length === 0) return '';
  
  return '?' + new URLSearchParams(validParams).toString();
}